* {
  text-align: center;
  justify-content: center;
}

body {
  height: 100%;
  background-color: var(--color-background);
  margin: 0;
  padding: 0;
  overflow-y: hidden;
}

:root {
  --color-tone-1: #000000;
  --color-tone-2: #787c7e;
  --color-tone-3: #878a8c;
  --color-tone-4: #d3d6da;
  --color-tone-5: #edeff1;
  --color-tone-6: #f6f7f8;
  --color-tone-7: #ffffff;
  --color-nav-hover: #f4f4f4;
  --opacity-50: rgba(255, 255, 255, 0.5);
  --green: #6aaa64;
  --yellow: #c9b458;
  --key-bg: var(--color-tone-4);
  --key-bg-present: var(--color-present);
  --key-bg-correct: var(--color-correct);
  --key-bg-absent: var(--color-absent);
  --color-present: var(--color-tone-4);
  --color-correct: var(--green);
  --color-possible: var(--yellow);
  --color-absent: var(--color-tone-2);
}

.tile {
  font-family: 'nyt-karnakcondensed';
  font-weight: 700;
  font-size: 37px;
  line-height: 100%;
  letter-spacing: 0.01em;
  text-align: center;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  text-transform: uppercase;
  color: var(--tile-text-color);
  /* pointer-events: none; */
/* } */
}

.title {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: bold;
  vertical-align: middle;
  box-sizing: border-box;
  user-select: none;
  text-align: center;
}

.row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 5px;
}

#game {
  width: 100%;
  max-width: var(--game-max-width);
  margin: 0 auto;
  height: calc(100% - var(--header-height));
  display: flex;
  flex-direction: column;
}

#board-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
}

#board {
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 5px;
  padding: 10px;
  box-sizing: border-box;
  width: 350px;
  height: 420px;
}

.word {
  padding-left: 1vw;
  padding-right: 1vw;
  display: inline-block;
}

.absentLetter {
  background-color: var(--color-absent);
}

.correctLetter {
  background-color: var(--color-correct);
}

.somewhereLetter {
  background-color: var(--color-present);
}

.possibleLetter {
  background-color: var(--color-possible);
}

.solvleInfo {
  margin-top: 3vh;
  font-size: 8pt;
}

.characterSet {
  padding-left: 8px;
  padding-right: 8px;
  display: inline-flex;
}